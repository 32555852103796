import { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Student } from '../../AutomatedAssessment/firestore/Student';
import { fetchBadHabitsOverTime } from '../../library/AssessmentAnalysis/BadHabitsOverTime';

ChartJS.register(...registerables);

interface GraphBadHabitsProps {
    courseId: string;
    student?: Student;
    id?: string;
}

const GraphBadHabits = ({ courseId, student, id }: GraphBadHabitsProps) => {

    const [max, setMax] = useState(20)
    const [graphValues, setGraphValues] = useState([22, 22, 22]);
    const studentId = student ? student.studentId : ""

    useEffect(() => {

        if (courseId === "") {
            setGraphValues([0, 0, 0])
        } else {
            fetchBadHabitsOverTime(courseId, studentId).then((competence) => {
                const result = competence.getResults()

                // determine maxium
                let maxVal = 9
                maxVal = (maxVal > result.duplication) ? maxVal : result.duplication;
                maxVal = (maxVal > result.badNaming) ? maxVal : result.badNaming;
                maxVal = (maxVal > result.deadCode) ? maxVal : result.deadCode;
                // Add 10% to graph axis
                setMax(Math.round(maxVal * 1.1))

                setGraphValues([result.duplication, result.badNaming, result.deadCode])
            })
        }

    }, [courseId, studentId])

    const chartScores = {
        labels: ["Duplication", "Bad Naming", "Dead Code"],
        datasets: [
            {
                data: graphValues,
                borderColor: 'rgba(255, 99, 132, 1.0)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                barPercentage: 0.5
            },
        ]
    };

    const chartOptions = {
        indexAxis: 'y' as const,
        responsive: true,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'right' as const,
            },
            title: {
                display: true,
                text: 'Bad Habits'
            },
        },
        scales: {
            x: {
                display: true,
                max: max,
                title: {
                    display: true,
                }
            },
        }
    }

    return (
        <div>
            <div className="code-assess-graph-title" id={id}>Bad Habits</div>
            <Bar options={chartOptions} data={chartScores} />
        </div>
    )
}

// const LiveViewChart = styled.div`
//     diplay:flex;
//     background: var(--colour-MAIN_BLUE);
//     border-radius: 5px;
//     max-height:100%;
// `
export default GraphBadHabits