import Footer from "./Footer";
import Header from "./Header";

export function pageWrapper<T>(Component: React.ComponentType<T>) {

    return (props: T) => (
        <>
            <Header />
            <Component {...props} />
            <Footer />
        </>);
}