import { ControlBox, ControlBoxTitle, ControlBoxSubtitle } from "./styles"

/**
 * Shows the number of students connect to current course
 * Course determined from teacher context.
 */
interface StudentCountProp {
    count: number,
    total: number,
}
const StudentCount = ({ count, total }: StudentCountProp) => {


    return (
        <ControlBox>
            <ControlBoxTitle>{count} / {total} </ControlBoxTitle>
            <ControlBoxSubtitle>Students Connected</ControlBoxSubtitle>
        </ControlBox>
    )
}


export default StudentCount