import styled from "styled-components"
import { createAssessmentLogin } from "../../AutomatedAssessment/user/AssessmentUser";
import { useState, ChangeEvent, useEffect } from "react";
import { useUser, SET_COURSE, CLEAR_COURSE } from "./../../contexts/UserContext"
import { saveCourse, CourseInterface, createCourse, retrieveCourse, Course } from "../../AutomatedAssessment/firestore/Course";

const HeaderLoggedIn = () => {
    const { state: userState, dispatch: userDispatch } = useUser()
    const [courseList, setCourseList] = useState<CourseInterface[]>([]);
    const [busy, setBusy] = useState<boolean>(true);

    useEffect(() => {
        // Get course list
        const loginHandler = createAssessmentLogin()
        loginHandler.runningCourseList().then((list) => {
            setCourseList(list)
            setBusy(false)

        })
    }, []);

    const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "logout") {
            console.log("logout")
            const loginHandler = createAssessmentLogin();
            loginHandler.signOut()
        } else {
            const selectedCourseId = event.target.value
            const course = courseList.find((item) => item.courseId === selectedCourseId)
            if (course === undefined) {
                userDispatch({ type: CLEAR_COURSE })
            } else {
                userDispatch({ type: SET_COURSE, payload: course })

                // Ensure the course has been added to the database
                retrieveCourse(course.courseId).then((existingCourse: Course | null) => {
                    if (existingCourse == null) {
                        saveCourse(createCourse(course));
                    }
                })
            }
        }
    }

    const courseListView = () => {
        if (busy) {
            return <LoaderWrapper ><div className="loader is-loading"></div></LoaderWrapper>
        }
        if (courseList.length === 0) {
            return <LoaderWrapper>No Courses</LoaderWrapper>
        }
        return <CourseSelect onChange={handleSelect} value={userState.currentCourse?.courseId}>
            <option>Please Select</option>
            {courseList.map((course) => {
                return <option key={course.courseId} value={course.courseId}>{course.name}</option>
            })}
            <Divider disabled>&nbsp;</Divider>
            <option value="logout" >Logout</option>
        </CourseSelect>
    }

    return (
        <LoginBox>
            <LoginTop>
                <LoginName>
                    <div>{userState.name}</div>
                    <div><strong>&nbsp;</strong></div>
                </LoginName>
                <div>
                    {userState.imageUrl && <LoginImage src={userState.imageUrl} />}
                    {!userState.imageUrl && <LoginImage src="/images/blank.png" />}
                </div>
            </LoginTop>
            <LoginBottom>
                {courseListView()}
            </LoginBottom>
        </LoginBox>
    )
}

const LoaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
`

const LoginBox = styled.div`
    width: 200px;
    background-color: var(--colour-MAIN_BLUE);
    border: 2px solid var(--colour-SAND_YELLOW);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-wrap: wrap;
    align-content: space-between;
`

const LoginName = styled.div`
    flex-grow: 1;
    // padding: 5px;
    justify-content: center;
    align-content: center;
    text-align: center;
    border-right: 2px var(--colour-SAND_YELLOW);
    background-color: var(--colour-GREY_2);
    border-radius: 8px 0 0 0;
`

const LoginSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`
const LoginTop = styled(LoginSection)`
    height: 41px;
    border-bottom: 2px solid var(--colour-SAND_YELLOW);
    font-size: 0.9em;
`
const LoginBottom = styled(LoginSection)`
    align-items: top;
    color: var(--colour-GREY_2);
`

const Divider = styled.option`
    background: gray;
    font-size: 1pt; 
`

const LoginImage = styled.img`
    display: flex;
    width: 40px;
    background-color: var(--colour-GREY_2);
    flex-grow:0;
    border-radius: 0 8px 0 0;
`
const CourseSelect = styled.select`
    background-color: var(--colour-MAIN_BLUE);
    display: flex;
    border: 0;
    padding: 0.5em 3em 0.5em 0.5em;
    margin: 1px;
    width: 100%;
    height: 36px;
    color: var(--colour-GREY_2);
    border-radius: 0 0 8px 8px;
    outline: 0;
    font-size:1em;
    text-align: center;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
        linear-gradient(45deg, transparent 50%, var(--colour-GREY_2) 50%),
        linear-gradient(135deg, var(--colour-GREY_2) 50%, transparent 50%),
        linear-gradient(to right, var(--colour-MAIN_BLUE), var(--colour-MAIN_BLUE));
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
`
/*

#login-box{
    width: 200px;
    height: 80px;
    background-color: var(--colour-MAIN_BLUE);
    border: 2px solid var(--colour-SAND_YELLOW);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-wrap: wrap;
    align-content: space-between;
}

#login-top{
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--colour-SAND_YELLOW);
    font-size: 0.9em;
}

#login-name{
    flex-grow: 1;
    padding: 5px;
    justify-content: center;
    align-content: center;
    text-align: center;
    border-right: 2px solid var(--colour-SAND_YELLOW);
    background-color: ${DullWhite};
    border-radius: 8px 0 0 0;
}

#login-name-name{
    font-weight:bold;
}
#login-name-school{

}

#login-picture{
    display: flex;
    width: 40px;
    background-color: ${DullWhite};
    flex-grow:0;
    border-radius: 0 8px 0 0;
}

#login-picture-image{
    width: 40px;
    height: 40px;
    border-radius: 0 8px 0 0;
}

#login-bottom{
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: ${DullWhite};
}

#login-class-class{
    background-color: var(--colour-MAIN_BLUE);
    border: 0;
    padding: 0.5em 3em 0.5em 0.5em;
    margin: 0;
    width: 100%;
    height: 36px;
    color: ${DullWhite};
    border-radius: 0 0 8px 8px;
    outline: 0;
    font-size:1em;
    text-align: center;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
        linear-gradient(45deg, transparent 50%, ${DullWhite} 50%),
        linear-gradient(135deg, ${DullWhite} 50%, transparent 50%),
        linear-gradient(to right, var(--colour-MAIN_BLUE, var(--colour-MAIN_BLUE));
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}


*/

export default HeaderLoggedIn;