import styled from "styled-components";

export const ControlBox = styled.div`
  background-color: var(--colour-MAIN_BLUE);
  border-radius: 5px;
  color: var(--colour-GREY_4);
  height: 100%;
`;

export const ControlBoxTitle = styled.div`
  font-size: 5em;
  text-align: center;
`;

export const ControlBoxSubtitle = styled.div`
  font-size: 2em;
  text-align: center;
`;

export const ControlBoxList = styled.div`
  text-align: center;
`;

export const ControlBoxLink = styled(ControlBox)`
  cursor: pointer;
`;


export const StudentLink = styled.a`
  color: var(--colour-WHITE);
`;
