import { ControlBox, ControlBoxTitle, ControlBoxSubtitle, ControlBoxList, StudentLink } from "./styles"
import { Student } from "../../AutomatedAssessment/firestore/Student";
import { useState } from "react";
import { useUser } from "../../contexts/UserContext";

/**
 * Lists the students based on assessment list
 */
interface StudentListProp {
    studentList: Student[];
    onStudentSelect?: (student: Student) => void;
}
const StudentList = ({ studentList, onStudentSelect }: StudentListProp) => {
    const { state: userState, dispatch: userDispatch } = useUser();

    const handleStudentSelect = (student: Student) => {
        if (onStudentSelect) {
            onStudentSelect(student);
        }
    }

    return (
        <>
            {studentList.length > 0 && (
                <ControlBox>
                    <ControlBoxSubtitle>
                        {userState.currentCourse?.name}
                    </ControlBoxSubtitle>
                    <div>
                        {studentList.map((student) => (
                            <ControlBoxList key={student.studentId}>
                                <StudentLink onClick={() => handleStudentSelect(student)}>{student.name}</StudentLink>
                            </ControlBoxList>
                        ))}
                    </div>
                </ControlBox>
            )}
            {studentList.length == 0 && (
                <ControlBox>
                    <ControlBoxTitle>&nbsp;</ControlBoxTitle>
                    <ControlBoxSubtitle>&nbsp;</ControlBoxSubtitle>
                </ControlBox>
            )}

        </>
    )
}


export default StudentList