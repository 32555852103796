import { Columns, Section } from "react-bulma-components";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { subscribeToCourseAssessmentList, } from "../AutomatedAssessment/firestore/FirestoreSubscribe";
import { usingFirebase } from "../AutomatedAssessment/firestore/Firestore";
import { Course, saveCourse, createCourse, retrieveCourse } from "../AutomatedAssessment/firestore/Course";

import { Student, StudentInterface } from "../AutomatedAssessment/firestore/Student";
import { useUser, SET_COURSE } from "./../contexts/UserContext";
import { getStudentsForAssessmentList } from "../AutomatedAssessment/firestore/FirestoreQuery";
import TeacherNavBar from "../components/Layout/TeacherNavBar";
import { Navbar } from "react-bulma-components"
import { Unsubscribe } from "@firebase/firestore";
import { Assessment } from "../AutomatedAssessment/firestore/Assessment";
import StudentCount from "../components/CourseInformation/StudentCount";
import CourseActivity from "../components/CourseInformation/CourseActivity";
import StudentList from "../components/CourseInformation/StudentList";
import PauseButton from "../components/CourseInformation/PauseButton";
import { EnjoymentRequest, saveEnjoymentRequest } from "../AutomatedAssessment/firestore/EnjoymentRequest";
import GraphAssessment from "../components/Graphs/GraphAssessment";
import GraphCompetence from "../components/Graphs/GraphCompetence";
import GraphEngagement from "../components/Graphs/GraphEngagement";
import GraphEnjoyment from "../components/Graphs/GraphEnjoyment";
import GraphBadHabits from "../components/Graphs/GraphBadHabits";

const LiveView = () => {
    const { state: userState, dispatch: userDispatch } = useUser();
    const [running, setRunning] = useState(false);
    const [assessmentList, setAssessmentList] = useState<Assessment[]>([]);
    const [studentList, setStudentList] = useState<Student[]>([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [student, setStudent] = useState<Student>(new Student())
    const [averageScore, setAverageScore] = useState<Assessment>(new Assessment());
    const [studentScore, setStudentScore] = useState<Assessment>(new Assessment());
    const [connectedCount, setConnectedCount] = useState(0);


    const a = new Student()

    // Monitor course changes.
    useEffect(() => {
        setErrorMsg("");

        // Ensure no course running  (clean up last one)
        setRunning(false);

        if (userState.currentCourse?.courseId) {
            const courseId = userState.currentCourse?.courseId;
            let unsubscribe: Unsubscribe;

            // Check if coures is paused
            usingFirebase().then(() => {
                retrieveCourse(courseId).then((course: Course | null) => {
                    if (course !== null) {
                        setRunning(!course.isPaused);

                        // Subscribe to course details.
                        const callBackFunction = (newList: Assessment[]) => {
                            usingFirebase().then(() => {
                                setAssessmentList(newList);
                            });
                        };
                        unsubscribe = subscribeToCourseAssessmentList(courseId, callBackFunction);
                    }
                });
            });

            return () => {
                // Make sure we unsubscribe when we switch courses
                if (unsubscribe !== undefined) {
                    unsubscribe();
                }
            };
        }
    }, [userState.currentCourse]);


    // Monitor Assessment list changes
    useEffect(() => {
        // Check if students have changed
        if (studentListNeedsUpdating(studentList, assessmentList)) {
            getStudentsForAssessmentList(assessmentList).then((newStudentList) => {
                const assessmentsSortedByDate = assessmentList.sort((a, b) => {
                    let comp = 0;
                    comp = (a.updatedAt > b.updatedAt) ? -1 : comp
                    return (a.updatedAt < b.updatedAt) ? 1 : comp
                })
                // Reminder to me - we can use the index position of "date sorted" array (above) to sort student list
                const sortedStudentList = newStudentList.sort((s1, s2) => {
                    return assessmentsSortedByDate.findIndex(a => a.studentId === s1.studentId) -
                        assessmentsSortedByDate.findIndex(a => a.studentId === s2.studentId);
                })
                setStudentList(sortedStudentList);
            });
        }

        setAverageScore(averageScoreCalc())
        setStudentScore(studentAssessment())

        const connected = assessmentList.filter(assessment => assessment.isActive).length
        setConnectedCount(connected);
    }, [assessmentList]);

    useEffect(() => {
        setStudentScore(studentAssessment())
    }, [student])

    const averageScoreCalc = (): Assessment => {
        const total = assessmentList.reduce(
            (prev: Assessment, current: Assessment) => {
                prev.abstraction += current.abstraction ?? 0;
                prev.dataRepresentation += current.dataRepresentation ?? 0;
                prev.flowControl += current.flowControl ?? 0;
                prev.interactivity += current.interactivity ?? 0;
                prev.logic += current.logic ?? 0;
                prev.parallelism += current.parallelism ?? 0;
                prev.synchronisation += current.synchronisation ?? 0;
                return prev;
            },
            new Assessment()
        );

        const count = assessmentList.length;
        if (count > 0) {
            total.abstraction = total.abstraction / count;
            total.dataRepresentation = total.dataRepresentation / count;
            total.flowControl = total.flowControl / count;
            total.interactivity = total.interactivity / count;
            total.logic = total.logic / count;
            total.parallelism = total.parallelism / count;
            total.synchronisation = total.synchronisation / count;
        }
        return total;
    };

    /**
     * Checks that all students in assessment list, there is a
     * corresponding student record in the student list, i.e. the student list
     * is up to date with the assessment list.
     */
    const studentListNeedsUpdating = (studentList: Student[], assessmentList: Assessment[]) => {
        if (studentList.length !== assessmentList.length) {
            return true;
        }
        // Find an assessment that doesn't have a student
        const assessment_idx = assessmentList.findIndex((assessment: Assessment) => {
            // If we can't find an associated student, then return found required assessment
            const studentIdx = studentList.findIndex(
                (student) => assessment.studentId === student.studentId
            );
            return studentIdx === -1;
        });
        // We found an assessment that didn't have a student
        return assessment_idx !== -1;
    }

    const studentAssessment = () => {
        const assessment = assessmentList.find((assessment: Assessment) => assessment.studentId === student.studentId)
        return assessment ? assessment : new Assessment()
    }

    const handlePlayClick = (current: boolean) => {
        let running_state = false;
        if (userState.currentCourse !== undefined) {
            const courseId = userState.currentCourse?.courseId ?? "";
            if (courseId === "") {
                setErrorMsg("Please select the course you wish to monitor");
            } else {
                // Toggle running state
                running_state = !current;
                // Save course
                const updatedCourse = createCourse(userState.currentCourse)
                updatedCourse.isPaused = !running_state;;
                saveCourse(updatedCourse)

                // Dispatch so other components can see change
                userDispatch({ type: SET_COURSE, payload: updatedCourse });
            }
        }
        setRunning(running_state)
    };

    const handleRequestFeedback = () => {
        const msg1 = "Please select the course for which you require feedback."
        const msg2 = "Please login and select the required course."

        if (userState.currentCourse !== undefined) {
            const currentCourse = userState.currentCourse
            const courseId = userState.currentCourse?.courseId ?? "";
            if (courseId === "") {
                setErrorMsg(msg1);
            } else {
                const enjoyment = new EnjoymentRequest(courseId)

                usingFirebase().then(() => {
                    saveEnjoymentRequest(enjoyment).then((enjoymentId: string) => {
                        // Save course
                        const updatedCourse = createCourse(currentCourse)
                        updatedCourse.addEnjoymentRequest(enjoymentId, enjoyment)

                        console.log("Update course " + updatedCourse.courseId + " enjoyment: " + enjoymentId)
                        saveCourse(updatedCourse)

                        // Dispatch so other components can see change
                        userDispatch({ type: SET_COURSE, payload: updatedCourse });
                    })
                })
            }
        } else {
            setErrorMsg(msg2);
        }
    }

    return (
        <>
            <TeacherNavBar>
                <Navbar.Item onClick={handleRequestFeedback}>Request Feedback</Navbar.Item>
            </TeacherNavBar>

            <Section>
                {errorMsg && <div className="notification is-warning">{errorMsg}</div>}

                <Columns>
                    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 10 }}>
                        <Columns>
                            <ControlColumn>
                                <StudentCount count={connectedCount} total={studentList.length} />
                            </ControlColumn>

                            <ControlColumn>
                                <CourseActivity assessments={assessmentList} />
                            </ControlColumn>

                            <ControlColumn>
                                <StudentList studentList={studentList} onStudentSelect={(student) => setStudent(student)} />
                            </ControlColumn>
                        </Columns>
                    </Columns.Column>

                    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 2 }}>
                        <PauseButton running={running} onPlayClick={handlePlayClick} />
                    </Columns.Column>
                </Columns>

                {running && (
                    <>
                        <Columns>
                            <GraphColumn>
                                <GraphAssessment assessmentScores={averageScore} />
                            </GraphColumn>
                            <GraphColumn>
                                {(student.studentId !== "") &&
                                    <GraphAssessment assessmentScores={studentScore} student={student} />
                                }
                            </GraphColumn>
                        </Columns>
                        {(student.studentId !== "") &&
                            <>
                                <Columns>
                                    <GraphColumn>
                                        <GraphCompetence student={student} courseId={userState.currentCourse ? userState.currentCourse.courseId : ""} />
                                    </GraphColumn>
                                    <GraphColumn>
                                        <GraphEngagement student={student} courseId={userState.currentCourse ? userState.currentCourse.courseId : ""} />
                                    </GraphColumn>
                                </Columns>
                                <Columns>
                                    <GraphColumn>
                                        <GraphEnjoyment student={student} courseId={userState.currentCourse ? userState.currentCourse.courseId : ""} />
                                    </GraphColumn>
                                    <GraphColumn>
                                        <GraphBadHabits student={student} courseId={userState.currentCourse ? userState.currentCourse.courseId : ""} />
                                    </GraphColumn>
                                </Columns>
                            </>
                        }
                    </>

                )}
            </Section>
        </>
    );
};

const ControlColumn = styled(Columns.Column).attrs(() => ({
    mobile: { size: "half" },
    tablet: { size: "one-third" },
}))``;
const GraphColumn = styled(Columns.Column).attrs(() => ({
    tablet: { size: 12 },
    desktop: { size: "half" }
}))``;

export default LiveView;
