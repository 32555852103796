import { Course, CourseInterface, createCourse } from "./Course"
import { getFirestoreDb } from "./Firestore"
import { doc, onSnapshot, Firestore, getFirestore, setDoc, collection, query, where } from "@firebase/firestore";

export const COLLECTION_TEACHERS = "Teachers"

export interface TeacherInterface {
    teacherId: string,
    name: string,
    updatedAt: Date,
}

export function createTeacher(teacherInterface: TeacherInterface) {
    const teacher = new Teacher()
    teacher.teacherId = teacherInterface.teacherId
    teacher.name = teacherInterface.name

    return teacher
}

export class Teacher implements TeacherInterface {
    public teacherId: string = ""
    public name: string = ""
    public updatedAt: Date = new Date
    public course?: Course

}

export function saveTeacher(teacher: Teacher) {
    if (teacher.teacherId == '') {
        throw ("Save Teacher - Teacher has not been set for assessment")
    }
    const key = teacher.teacherId

    // Record save information
    teacher.updatedAt = new Date()

    const teacherData = copyTeacher(teacher, {})

    const db = getFirestoreDb();
    setDoc(doc(db, COLLECTION_TEACHERS, key), teacherData, { merge: true });
}

function copyTeacher(fromTeacher: TeacherInterface, toTeacher: any ) :TeacherInterface {

    toTeacher.teacherId = fromTeacher.teacherId
    toTeacher.name = fromTeacher.name
    toTeacher.updatedAt = fromTeacher.updatedAt

    return toTeacher as TeacherInterface
}