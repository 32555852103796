import { useEffect, useState } from "react"
import { Assessment } from "../../AutomatedAssessment/firestore/Assessment"
import { activityPercentage } from "../../library/AssessmentAnalysis/AssessmentStatstics"
import { ControlBox, ControlBoxTitle, ControlBoxSubtitle } from "./styles"

/**
 * Shows the percentage of students who are currently active on course
 */
interface CourseActivityProps {
    assessments: Assessment[]
}
const CourseActivity = ({ assessments }: CourseActivityProps) => {
    const [activity, setActivity] = useState(0)

    useEffect(() => {
        setActivity(activityPercentage(assessments))

    }, [assessments])

    return (
        <ControlBox>
            <ControlBoxTitle>{activity}%</ControlBoxTitle>
            <ControlBoxSubtitle>Active</ControlBoxSubtitle>
        </ControlBox>
    )
}

export default CourseActivity