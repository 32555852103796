import dayjs, { ManipulateType } from "dayjs";
import { CourseEnjoyment } from "../../AutomatedAssessment/firestore/CourseEnjoyment";
import { usingFirebase } from "../../AutomatedAssessment/firestore/Firestore";
import { getEnjoymentList, getStudentEnjoymentList } from "../../AutomatedAssessment/firestore/FirestoreQuery";


/**
 * Provides an enjoyment over time object (via promise) for the course and student passed.
 * If Student id is blank, get's average for students.
 */
export function fetchEnjoymentOverTime(courseId: string, studentId: string): Promise<EnjoymentOverTime> {
    return new Promise<EnjoymentOverTime>((resolve, reject) => {
        if (courseId === "") {
            resolve(new EnjoymentOverTime([]));
        }
        const courseEnjoymentFetch = (courseId: string, studentId: string, since: Date): Promise<CourseEnjoyment[]> => {
            if (studentId === "") {
                return getEnjoymentList(courseId, since)
            }
            return getStudentEnjoymentList(courseId, studentId, since)
        }

        usingFirebase()
            .then(() => {
                let since = dayjs().startOf("month").subtract(6, "month");

                courseEnjoymentFetch(courseId, studentId, since.toDate())
                    .then((enjoymentList: CourseEnjoyment[]) => {
                        const enjoymentOverTime = new EnjoymentOverTime(enjoymentList);

                        resolve(enjoymentOverTime);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            })
            .catch(() => {
                reject("Unable to connect to database");
            });
    });
}

/**
 * An time interval that can be used for grouping assessments.
 * Assessments can be group by day/week/month
 */
interface TimeSlotInterface {
    startTime: dayjs.Dayjs;
    endTime: dayjs.Dayjs;
    period: ManipulateType;
}


/**
 * Has a list of assessment score ranges for the period defined
 * by the time slot
 */
export interface CourseEnjoymentRatingBucket {
    timeSlot: TimeSlotInterface;
    highest: number;
    average: number;
    lowest: number;
}

interface CourseEnjoymentBucket {
    timeSlot: TimeSlotInterface;
    courseEnjoymentList: CourseEnjoyment[];
}

class EnjoymentOverTime {
    private enjoymentList: CourseEnjoyment[]
    private timeSlots?: TimeSlotInterface[]
    private timeSlotCount: number = 6


    constructor(enjoymentList: CourseEnjoyment[]) {
        this.enjoymentList = enjoymentList;
    }

    /**
     * Provides list Enjoyment Rating Range buckets
     *
     */
    public enjoymentRangeBucketList(): CourseEnjoymentRatingBucket[] {
        // Make sure we've create a set of time slots 
        this.checkTimeSlots()

        // Place the course enjoyment scores we have in approprate timeslot bucket
        const bucketList: CourseEnjoymentBucket[] = [];
        const buckets = this.timeSlots ?? [];

        buckets.forEach((timeSlot: TimeSlotInterface) => {
            const forThisBucket = this.enjoymentList.filter((enjoyment: CourseEnjoyment) => {
                // Is the course enjoyment in the time slot
                const ratedAt = dayjs(enjoyment.ratedAt);
                return ratedAt >= timeSlot.startTime && ratedAt < timeSlot.endTime;
            });
            bucketList.push({
                timeSlot: timeSlot,
                courseEnjoymentList: forThisBucket,
            });
        });

        // Go through buckets to lowest/average/highest ratings
        const ratingArray: CourseEnjoymentRatingBucket[] = []
        bucketList.forEach(bucket => {
            let highest = 0, average = 0, lowest = 0, total = 0

            if (bucket.courseEnjoymentList.length > 0) {
                bucket.courseEnjoymentList.forEach((enjoyment) => {
                    highest = (enjoyment.rating > 0 && enjoyment.rating > highest) ? enjoyment.rating : highest;
                    // ensure we have a lowest rating.
                    lowest = (lowest === 0) ? enjoyment.rating : lowest
                    lowest = (enjoyment.rating > 0 && enjoyment.rating < lowest) ? enjoyment.rating : lowest;
                    total += enjoyment.rating
                })
                average = total / bucket.courseEnjoymentList.length
            }
            ratingArray.push({
                timeSlot: bucket.timeSlot,
                highest: highest,
                average: average,
                lowest: lowest,
            })

        })

        return ratingArray
    }

    /**
    * Timeslots are included with each datasets
    */
    public labelTimeLine(): string[] {
        this.checkTimeSlots();
        const timeSlots: TimeSlotInterface[] = this.timeSlots ?? [];

        return timeSlots.map((timeSlot) => {
            let format = "D MMM YY";
            if (timeSlot.period === "month") {
                format = "MMM YY";
            }
            return timeSlot.endTime.subtract(1, "day").format(format);
        });
    }

    /**
     * Ensure the timeslots have been created based on the assessments used
     * to create object.
     */
    private checkTimeSlots() {
        if (this.timeSlots !== undefined) {
            return;
        }
        this.timeSlots = [];

        if (this.enjoymentList.length > 0) {
            this.timeSlots = determineTimeSlots(this.enjoymentList, this.timeSlotCount);
        }
    }
}

/**
 * Given a set of course enjoyment results  determines the time slots to be used
 * - the time slot period being based on what period there is data is available.
 *
 */
const determineTimeSlots = (enjoymentList: CourseEnjoyment[], slotCount: number): TimeSlotInterface[] => {
    if (enjoymentList.length === 0) {
        return [];
    }

    let period: ManipulateType = "month";

    const start = dayjs(enjoymentList[0].ratedAt);
    const now = dayjs();
    const diff = now.add(1, "week").startOf("week").diff(start, "days");

    let endTime = now.add(1, "month").startOf("month");
    // if earliest data is less than "slot Count" weeks ago, then use a week time slot period
    if (diff < 7 * slotCount) {
        period = "week";
        endTime = now.add(1, "week").startOf("week");
    }
    // if earliest data is less than "slot Count" days ago, then use a day time slot period
    if (diff <= slotCount) {
        period = "day";
        endTime = now.add(1, "day");
    }

    const timeSlots: TimeSlotInterface[] = [];
    // Get the required number of slot
    for (let i = 0; i < slotCount; i++) {
        const startTime = endTime.subtract(1, period);
        timeSlots.unshift({
            startTime,
            endTime,
            period,
        });
        endTime = startTime;
    }
    return timeSlots;
};