import { Button } from "react-bulma-components";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SideMenu = () => {

    return (
        <SidePanel>
            <MenuContainer>
                <SideButtonLink renderAs={Link} color="primary" to="/">
                    Class Summary
                </SideButtonLink>
                <SideButtonLink renderAs={Link} color="primary" to="/live-view">
                    Live View
                </SideButtonLink>
                <SideButtonLink renderAs={Link} color="primary" to="/students">
                    Students
                </SideButtonLink>

                <Separator />

                <SideButton onClick={() => window.location.replace("#graph-competency")}>Confidence in competence</SideButton>
                <SideButton onClick={() => window.location.replace("#graph-enjoyment")}>Enjoyment</SideButton>
                <SideButton onClick={() => window.location.replace("#graph-engagement")}>Engagement</SideButton>
                <SideButton onClick={() => window.location.replace("#graph-bad-habit")}>Bad Habits</SideButton>
            </MenuContainer>
        </SidePanel>
    );
};

const SidePanel = styled.div`
    position: sticky;
    display: inline-block;
    vertical-align: top;
    max-height: 100vh;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    border-right: 2px solid var(--colour-GREY_3);
    height: 100%;
    width: 100%;
`;

const MenuContainer = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SideButton = styled(Button)`
    border-width: 2px;
    width: 80%;
    height: 50px;
    margin: 10px;
`;

const SideButtonLink = styled(SideButton)`
    border-color: var(--colour-MAIN_BLUE) !important;
    color: #1a1a1a !important;
    font-weight: bold;
`;

const Separator = styled.hr`
    width: 60%;
    border: 1px solid var(--colour-GREY_3);
`;

export default SideMenu;
