import { usingFirebase } from "../../AutomatedAssessment/firestore/Firestore";
import { getAssessmentHistory, getStudentAssessmentHistory } from "../../AutomatedAssessment/firestore/FirestoreQuery";
import dayjs, { ManipulateType } from "dayjs";
import { averageAssessmentScoreAverage, totalScore } from "../../AutomatedAssessment/models/assessment/AssessmentScore";
import { Assessment } from "../../AutomatedAssessment/firestore/Assessment";

/**
 * Provides an assessment over time object (via promise) for the course passed.
 */
export function fetchAssessmentOverTime(courseId: string, studentId: string): Promise<AssessmentOverTime> {
    return new Promise<AssessmentOverTime>((resolve, reject) => {
        if (courseId === "") {
            resolve(new AssessmentOverTime([]));
        }
        const assessmentFetch = (courseId: string, studentId: string, since: Date): Promise<Assessment[]> => {
            if (studentId === "") {
                return getAssessmentHistory(courseId, since)
            }
            return getStudentAssessmentHistory(courseId, studentId, since)
        }

        usingFirebase()
            .then(() => {
                let since = dayjs().startOf("month").subtract(6, "month");

                assessmentFetch(courseId, studentId, since.toDate())
                    .then((assessmentList: Assessment[]) => {
                        const assessmentOverTime = new AssessmentOverTime(assessmentList);

                        resolve(assessmentOverTime);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            })
            .catch(() => {
                reject("Unable to connect to database");
            });
    });
}

/**
 * An time interval that can be used for grouping assessments.
 * Assessments can be group by day/week/month
 */
interface TimeSlotInterface {
    startTime: dayjs.Dayjs;
    endTime: dayjs.Dayjs;
    period: ManipulateType;
}

/**
 * Score range holds the lowest, average and highest
 * data scores for any grouping of assessments.
 * The student count holds the number of students for which there
 * were assessment scores available.
 */
interface ScoreRange {
    lowest: number;
    average: number;
    highest: number;
    studentCount: number;
}

/**
 * Has a list of assessments for the period defined
 * by the time slot
 */
export interface AssessmentScoreBucket {
    timeSlot: TimeSlotInterface;
    assessments: Assessment[];
}

/**
 * Has a list of assessment score ranges for the period defined
 * by the time slot
 */
export interface AssessmentScoreRangeBucket {
    timeSlot: TimeSlotInterface;
    scoreRange: ScoreRange;
}

/**
 * Assessment over time
 *
 * Given a list of assessment scores, provides various statically information.
 *
 */
class AssessmentOverTime {
    private timeSlotCount: number = 6;
    private assessmentList: Assessment[];
    private timeSlots?: TimeSlotInterface[];
    private assessmentData?: AssessmentScoreBucket[];

    constructor(assessmentList: Assessment[]) {
        this.assessmentList = assessmentList;
    }

    /**
     * Provides list of Assessment Score Range buckets based on the assessment list
     * used to created object.
     * Note: the timeslot period is automatically sized based on the data available.
     *
     */
    public assessmentScoreRangeBucketList(): AssessmentScoreRangeBucket[] {
        this.checkScoreDataSet();

        return (this.assessmentData as AssessmentScoreBucket[]).map((scoreDataSet) => {
            return {
                timeSlot: scoreDataSet.timeSlot,
                scoreRange: createScoreRangeForAssessments(scoreDataSet.assessments),
            };
        });
    }

    /**
     * Provides list Assessment Score Range buckets, with the score values as PERCENTAGES.
     *
     * Note: the timeslot period is automatically sized based on the data available.
     *
     */
    public assessmentPercentageRangeBucketList(): AssessmentScoreRangeBucket[] {
        const maxPoints = 21;
        return this.assessmentScoreRangeBucketList().map((score: AssessmentScoreRangeBucket) => {
            // Adjust to percentage
            score.scoreRange.highest = (100 * score.scoreRange.highest) / maxPoints;
            score.scoreRange.average = (100 * score.scoreRange.average) / maxPoints;
            score.scoreRange.lowest = (100 * score.scoreRange.lowest) / maxPoints;

            return {
                timeSlot: score.timeSlot,
                scoreRange: score.scoreRange,
            };
        });
    }

    /**
     * Provides list Assessment Score Compentency buckets, with the score values as PERCENTAGES.
     *
     * Note: the timeslot period is automatically sized based on the data available.
     *
     */
    public assessmentCompentencyBucketList(): AssessmentScoreRangeBucket[] {
        const maxPoints = 21;
        let lowest = 0, average = 0, highest = 0

        return this.assessmentScoreRangeBucketList().map((score: AssessmentScoreRangeBucket) => {
            highest = (score.scoreRange.highest > highest) ? score.scoreRange.highest : highest
            average = (score.scoreRange.average > average) ? score.scoreRange.average : average
            lowest = (score.scoreRange.lowest > lowest) ? score.scoreRange.lowest : lowest
            // Adjust to percentage
            score.scoreRange.highest = (100 * highest) / maxPoints;
            score.scoreRange.average = (100 * average) / maxPoints;
            score.scoreRange.lowest = (100 * lowest) / maxPoints;

            return {
                timeSlot: score.timeSlot,
                scoreRange: score.scoreRange,
            };
        });
    }

    /**
     * Provides list Engagement Score Range buckets
     *
     */
    public engagementRangeBucketList(): AssessmentScoreRangeBucket[] {
        this.checkScoreDataSet();

        return (this.assessmentData as AssessmentScoreBucket[]).map((scoreDataSet) => {
            return {
                timeSlot: scoreDataSet.timeSlot,
                scoreRange: createEngagementRangeForAssessments(scoreDataSet.assessments),
            };
        });
    }


    /**
     * Timeslots are included with each datasets
     */
    public labelTimeLine(): string[] {
        this.checkTimeSlots();
        const timeSlots: TimeSlotInterface[] = this.timeSlots ?? [];

        return timeSlots.map((timeSlot) => {
            let format = "D MMM YY";
            if (timeSlot.period === "month") {
                format = "MMM YY";
            }
            return timeSlot.endTime.subtract(1, "day").format(format);
        });
    }

    /**
     * Ensure the timeslots have been created based on the assessments used
     * to create object.
     */
    private checkTimeSlots() {
        if (this.timeSlots !== undefined) {
            return;
        }
        this.timeSlots = [];

        if (this.assessmentList.length > 0) {
            this.timeSlots = determineTimeSlots(this.assessmentList, this.timeSlotCount);
        }
    }

    /**
     * Ensure the assessment score buckets have been created based on the assessments
     * used to create object.
     */
    private checkScoreDataSet() {
        if (this.assessmentData !== undefined) {
            return;
        }
        this.checkTimeSlots();
        const timeSlotList: TimeSlotInterface[] = this.timeSlots as TimeSlotInterface[];
        this.assessmentData = splitToBucketTuple(timeSlotList, this.assessmentList);
    }
}

/**
 * Score range gives highest, lowest and average score for students for any set of assessment data.
 * This function creates the score range for assessments given
 *
 * @returns
 */
const createScoreRangeForAssessments = (assessments: Assessment[]): ScoreRange => {
    const range: ScoreRange = {
        lowest: 0,
        average: 0,
        highest: 0,
        studentCount: 0,
    };

    // Unique list of students from this assessment list
    const students = [...Array.from(new Set(assessments.map((assessment) => assessment.studentId)))];

    students.forEach((studentId: string) => {
        // Get score for this student
        const studentScore = averageAssessmentScoreAverage(assessments.filter((assessment) => assessment.studentId === studentId));
        const total = totalScore(studentScore);
        if (range.studentCount === 0) {
            range.lowest = total;
            range.average = total;
            range.highest = total;
        } else {
            if (range.lowest > total) {
                range.lowest = total;
            }
            if (range.highest < total) {
                range.highest = total;
            }
            range.average += total;
        }
        ++range.studentCount;
    });

    // Adjust the average
    if (range.studentCount > 1) {
        range.average /= range.studentCount;
    }

    return range;
};

/**
 * Given a set of assessment this determines the time slots to be used
 * - the time slot period being based on what period there is data is available.
 *
 */
const determineTimeSlots = (assessments: Assessment[], slotCount: number): TimeSlotInterface[] => {
    if (assessments.length === 0) {
        return [];
    }

    let period: ManipulateType = "month";

    const start = dayjs(assessments[0].updatedAt);
    const now = dayjs();
    const diff = now.diff(start, "days");
    let endTime = now.add(1, "month").startOf("month");
    // if earliest data is less than "slot Count" weeks ago, then use a week time slot period
    if (diff < 7 * slotCount) {
        period = "week";
        endTime = now.add(1, "week").startOf("week");
    }
    // if earliest data is less than "slot Count" days ago, then use a day time slot period
    if (diff <= slotCount) {
        period = "day";
        endTime = now.add(1, "day");
    }

    const timeSlots: TimeSlotInterface[] = [];
    // Get the required number of slot
    for (let i = 0; i < slotCount; i++) {
        const startTime = endTime.subtract(1, period);
        timeSlots.unshift({
            startTime,
            endTime,
            period,
        });
        endTime = startTime;
    }
    return timeSlots;
};

const splitToBucketTuple = (buckets: TimeSlotInterface[], assessments: Assessment[]): AssessmentScoreBucket[] => {
    const bucketList: AssessmentScoreBucket[] = [];

    buckets.forEach((timeSlot: TimeSlotInterface) => {
        const bucketAssessments = assessments.filter((assessment: Assessment) => {
            const updatedAt = dayjs(assessment.updatedAt);
            return updatedAt >= timeSlot.startTime && updatedAt < timeSlot.endTime;
        });
        bucketList.push({
            timeSlot: timeSlot,
            assessments: bucketAssessments,
        });
    });

    return bucketList;
};

const createEngagementRangeForAssessments = (assessments: Assessment[]): ScoreRange => {
    const range: ScoreRange = {
        lowest: 0,
        average: 0,
        highest: 0,
        studentCount: 0,
    };

    // Unique list of students from this assessment list
    const students = [...Array.from(new Set(assessments.map((assessment) => assessment.studentId)))];

    students.forEach((studentId: string) => {
        const studentAssessments = assessments.filter((assessment) => assessment.studentId === studentId);
        // How to measure engagement?
        // temp, number of assessments in db
        const engagement = studentAssessments.length;

        // Get highest/lowest engagement

        if (range.studentCount === 0) {
            range.lowest = engagement;
            range.average = engagement;
            range.highest = engagement;
        } else {
            if (range.lowest > engagement) {
                range.lowest = engagement;
            }
            if (range.highest < engagement) {
                range.highest = engagement;
            }
            range.average += engagement;
        }
        ++range.studentCount;
    });

    // Adjust the average
    if (range.studentCount > 1) {
        range.average /= range.studentCount;
    }

    return range;
};

