import styled from "styled-components";
import GraphCompetenceConfidence from "../Graphs/GraphCompetence";
import GraphEnjoyment from "../Graphs/GraphEnjoyment";
import GraphEngagement from "../Graphs/GraphEngagement";
import { useUser } from "../../contexts/UserContext";
import { useEffect, useState } from "react";
import { usingFirebase } from "../../AutomatedAssessment/firestore/Firestore";
import { getAssessments } from "../../AutomatedAssessment/firestore/FirestoreQuery";
import GraphBadHabits from "../Graphs/GraphBadHabits";

const Dashboard = () => {
    const { state: userState } = useUser();
    const [studentCount, setStudentCount] = useState(0);

    const courseId = userState.currentCourse ? userState.currentCourse.courseId : "";

    useEffect(() => {
        const getStudentCount = async () => {
            if (userState.currentCourse) {
                const courseId = userState.currentCourse?.courseId;
                usingFirebase().then(() => {
                    getAssessments(courseId).then((studentList) => {
                        setStudentCount(studentList.length);
                    })
                })

            } else {
                setStudentCount(0);
            }
        };
        getStudentCount();
    }, [userState.currentCourse]);

    return (
        <div>
            <PageTitle className="title is-1 mt-4">Robotics Class</PageTitle>

            {studentCount > 0 && (
                <h2 className="title is-3">
                    {studentCount} students, {userState.currentCourse?.name}{" "}
                </h2>
            )}

            <GraphContainer>
                <GraphCompetenceConfidence courseId={courseId} id="graph-competency"></GraphCompetenceConfidence>
            </GraphContainer>

            <GraphContainer>
                <GraphEnjoyment courseId={courseId} id="graph-enjoyment"></GraphEnjoyment>
            </GraphContainer>

            <GraphContainer>
                <GraphEngagement courseId={courseId} id="graph-engagement"></GraphEngagement>
            </GraphContainer>

            <GraphContainer>
                <GraphBadHabits courseId={courseId} id="graph-bad-habit"></GraphBadHabits>
            </GraphContainer>
        </div>
    );
};

const GraphContainer = styled.div`
    margin-bottom: 2em;
`;
const PageTitle = styled.h1``;

export default Dashboard;
