import * as React from 'react'
import { usingFirebase } from '../AutomatedAssessment/firestore/Firestore'
import { saveTeacher, Teacher } from '../AutomatedAssessment/firestore/Teacher'
import { CourseInterface, createCourseStruct } from '../AutomatedAssessment/firestore/Course'
import { AssessmentUserInterface, createAssessmentUser } from '../AutomatedAssessment/user/AssessmentUser'
import dayjs from "dayjs";

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const SET_COURSE = 'set_course'
export const CLEAR_COURSE = 'clear_course'

type ActionType = 'login' | 'logout' | 'set_course' | 'clear_course'

type Action = { type: ActionType, payload?: any }
type Dispatch = (action: Action) => void
type UserProviderProps = { children: React.ReactNode }


const UserContext = React.createContext<
    { state: AssessmentUserInterface; dispatch: Dispatch } | undefined
>(undefined)

function userReducer(state: AssessmentUserInterface, action: Action) {
    switch (action.type) {
        case LOGIN: {
            // Ensure this teacher is saved to database
            const user = action.payload as AssessmentUserInterface;
            const teacher = new Teacher()
            teacher.teacherId = user.id
            teacher.name = user.name
            usingFirebase().then(() => saveTeacher(teacher))

            return { ...state, ...action.payload }
        }
        case LOGOUT: {
            return createAssessmentUser()
        }
        case SET_COURSE: {
            const course = action.payload as CourseInterface
            const classroomUpdate = {
                currentCourse: course,
            }
            return { ...state, ...classroomUpdate }
        }
        case CLEAR_COURSE: {
            const updatedUser = { ...state }
            delete updatedUser.currentCourse
            return updatedUser
        }
        default: {
            throw new Error(`Unhandled action type: Action Type`)
        }
    }
}

function UserProvider({ children }: UserProviderProps) {
    const [state, dispatch] = React.useReducer(userReducer, createAssessmentUser())
    const value = { state, dispatch }
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

function useUser() {
    const context = React.useContext(UserContext)
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider')
    }
    return context
}

export { UserProvider, useUser }
