import { useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js";
import { Bar } from "react-chartjs-2";
import { registerables } from "chart.js";
import { AssessmentScoreRangeBucket, fetchAssessmentOverTime } from "../../library/AssessmentAnalysis/AssessmentOverTime";
import { Student } from "../../AutomatedAssessment/firestore/Student";

ChartJS.register(...registerables);

interface GraphEngagementProps {
    courseId: string;
    id?: string;
    student?: Student;
}
interface DataSet {
    label: string;
    fill?: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    barPercentage: number;

    data: number[][];
}
interface GraphData {
    labels: string[];
    datasets: DataSet[];
}

const GraphEngagement = ({ courseId, id, student }: GraphEngagementProps) => {
    // Graph data stored in state
    const createNewData = (): GraphData => {
        return { labels: [], datasets: [] };
    };
    const [graphData, setgraphData] = useState<GraphData>(createNewData());
    const studentId = student ? student.studentId : ""

    useEffect(() => {
        if (courseId === "") {
            setgraphData(createNewData());
        } else {
            fetchAssessmentOverTime(courseId, studentId).then((competence) => {
                // console.log(competence)
                const results = competence.engagementRangeBucketList();

                const newData = createNewData();

                newData.labels = competence.labelTimeLine();

                const highest = results.map((dataPoint: AssessmentScoreRangeBucket) => [dataPoint.scoreRange.average, dataPoint.scoreRange.highest]);
                const lowest = results.map((dataPoint: AssessmentScoreRangeBucket) => [dataPoint.scoreRange.lowest, dataPoint.scoreRange.average]);
                const average = results.map((dataPoint: AssessmentScoreRangeBucket) => [0, dataPoint.scoreRange.average]);

                if (student) {
                    newData.datasets.push({
                        label: student.name,
                        data: average,
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        borderWidth: 2,
                        barPercentage: 0.6,
                    });
                } else {
                newData.datasets.push({
                    label: "Highest",
                    data: highest,
                    backgroundColor: "rgba(75, 192, 192, 0.5)",
                    borderColor: "rgba(75, 192, 192, 1)",
                    borderWidth: 0,
                    barPercentage: 0.4,
                });
                newData.datasets.push({
                    label: "Lowest",
                    data: lowest,
                    backgroundColor: "rgba(255, 99, 132, 0.25)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 0,
                    barPercentage: 0.4,
                });
                newData.datasets.push({
                    label: "Average",
                    data: average,
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 2,
                    barPercentage: 0.6,
                });
            }

                setgraphData(newData);
            });
        }
    }, [courseId, studentId]);

    const title = "Engagement"

    const chartOptions = {
        responsive: true,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        plugins: {
            title: {
                display: true,
                text: title,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: false,
            },
        },
    };

    return (
        <div>
            <div className="code-assess-graph-title" id={id}>Engagement</div>
            <Bar options={chartOptions} data={graphData} />
        </div>
    );
};

export default GraphEngagement;
