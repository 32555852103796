import { Columns } from "react-bulma-components";
import SideMenu from "../components/Layout/SideMenu";
import Dashboard from "../components/Dashboard/Dashboard";

const HomeScreen = () => {
    return (
        <div>
            <Columns>
                <Columns.Column
                    mobile={{ size: "half" }}
                    tablet={{ size: "one-third" }}
                    desktop={{ size: "one-quarter" }}
                    widescreen={{ size: "one-quarter" }}
                    fullhd={{ size: "one-fifth" }}
                >
                    <SideMenu />
                </Columns.Column>
                <Columns.Column
                    mobile={{ size: "half" }}
                    tablet={{ size: "two-thirds" }}
                    desktop={{ size: "three-quarters" }}
                    widescreen={{ size: "three-quarters" }}
                    fullhd={{ size: "four-fifths" }}
                >
                    <Dashboard></Dashboard>
                </Columns.Column>
            </Columns>
        </div>
    );
};

export default HomeScreen;
