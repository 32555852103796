import TeacherNavBar from "../components/Layout/TeacherNavBar";
import { Columns, Section } from "react-bulma-components";
import { useUser } from "../contexts/UserContext";
import { useEffect, useState } from "react";
import { usingFirebase } from "../AutomatedAssessment/firestore/Firestore";
import { getAssessments, getStudentsForAssessmentList } from "../AutomatedAssessment/firestore/FirestoreQuery";
import { Student } from "../AutomatedAssessment/firestore/Student";
import styled from "styled-components";
import { Assessment } from "../AutomatedAssessment/firestore/Assessment";
import GraphCompetence from "../components/Graphs/GraphCompetence";
import GraphEnjoyment from "../components/Graphs/GraphEnjoyment";
import GraphEngagement from "../components/Graphs/GraphEngagement";

const StudentsView = () => {
    const { state: userState } = useUser();
    const [studentList, setStudentList] = useState<Student[]>([])
    const [student, setStudent] = useState<Student>(new Student())

    const courseId = (userState.currentCourse) ? userState.currentCourse?.courseId : ""

    useEffect(() => {
        const getStudentList = async () => {
            if (userState.currentCourse) {
                const courseId = userState.currentCourse?.courseId;
                usingFirebase().then(() => {
                    getAssessments(courseId).then((list: Assessment[]) => {
                        getStudentsForAssessmentList(list).then((students) => {
                            setStudentList(students)
                        })
                    })
                })
            } else {
                setStudentList([]);
            }
        };
        getStudentList();
    }, [userState.currentCourse]);

    const graphColumn = () => {
        return (
            <>
                <GraphColoumnHeader>{student.name}</GraphColoumnHeader>

                <GraphCompetence courseId={courseId} student={student} />
                <br />
                <GraphEnjoyment courseId={courseId} student={student} />
                <br />
                <GraphEngagement courseId={courseId} student={student} />
            </>
        )
    }

    return (
        <>
            <TeacherNavBar />

            <Section>
                <Columns>
                    <Columns.Column className="is-one-quarter" >
                        <ColumnHeader>Students</ColumnHeader>
                        {studentList.map((student) => (
                            <StudentRow key={student.studentId}>
                                <StudentSelect onClick={() => setStudent(student)}>{student.name}</StudentSelect>
                            </StudentRow>
                        ))}

                    </Columns.Column>
                    <Columns.Column >
                        {(student.studentId !== "") && graphColumn()}
                    </Columns.Column>
                </Columns>
            </Section>
        </>
    );
}

export default StudentsView

const StudentRow = styled.div`
//   text-align: center;
`;
const ColumnHeader = styled.div`
    font-size: 1.1em;
    font-weight: bold;
`
const GraphColoumnHeader = styled.div`
    font-size: 2em;
    font-weight: bold;
    text-align:center;
`
const StudentSelect = styled.button`
    border: none;
    cursor: pointer;
`