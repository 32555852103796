import React from "react";
import { Navbar } from "react-bulma-components"
import { Link } from "react-router-dom"
import { useUser } from "../../contexts/UserContext";
import { createAssessmentLogin } from "../../AutomatedAssessment/user/AssessmentUser";

interface TeacherNavBarProps {
    children?: JSX.Element
}

const TeacherNavBar = ({ children }: TeacherNavBarProps) => {
    const { state: userState, dispatch: userDispatch } = useUser()
    const [isActive, setIsActive] = React.useState(false);

    const handleLogout = () => {
        console.log("click")
        const loginHandler = createAssessmentLogin();
        loginHandler.signOut()
    }

    return (
        <Navbar>
            <Navbar.Brand>
                <Navbar.Burger onClick={() => setIsActive(!isActive)} />
            </Navbar.Brand>

            <Navbar.Menu className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <Navbar.Container>
                    <Navbar.Item renderAs={Link} to={{ pathname: '/' }}>Home</Navbar.Item>
                    { children }
                    {userState.isLoggedIn && <Navbar.Item onClick={handleLogout}>Logout</Navbar.Item>}
                </Navbar.Container>

            </Navbar.Menu>
        </Navbar>
    )
}

export default TeacherNavBar