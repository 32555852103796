import styled from 'styled-components';
import HeaderLoggedIn from "./HeaderLoggedIn";
import HeaderLogin from "./HeaderLogin";
import { Columns } from "react-bulma-components";
import { useUser } from "../../contexts/UserContext"

const Header = () => {
    const { state: userState } = useUser()

    return (
        <HeaderContainer>
            <Columns>
                <LeftColumn
                    mobile={{ size: "half" }}
                    tablet={{ size: "one-third" }}
                    desktop={{ size: "one-quarter" }}
                    widescreen={{ size: "one-quarter" }}
                    fullhd={{ size: "one-fifth" }}
                >
                    <Logo src="images/robotical_logo.svg" />
                </LeftColumn>

                {/* <div className="column has-text-centered"> */}
                <CentralColumn>
                    <a href="/" className="title has-text-light ">Code.Assess</a>
                </CentralColumn>

                <RightColumn
                    mobile={{ size: "half" }}
                    tablet={{ size: "one-third" }}
                    desktop={{ size: "one-quarter" }}
                    widescreen={{ size: "one-quarter" }}
                    fullhd={{ size: "one-fifth" }}
                >

                    {userState.isLoggedIn && <HeaderLoggedIn />}
                    {!userState.isLoggedIn && <HeaderLogin />}
                </RightColumn>
            </Columns>
        </HeaderContainer>
    )
}

const HeaderContainer = styled.header`
    padding: 10px;
     background: var(--colour-LIGHT_BLUE);
`

const LeftColumn = styled(Columns.Column)`
    display: flex;
    align-items: center;
    justify-content: center;
`

const CentralColumn = styled(Columns.Column)`
    display: flex;
    align-items: center;
    justify-content: center;
`

const RightColumn = styled(Columns.Column)`
    display: flex;
    justify-content: flex-end;
`

const Logo = styled.img`   
    height: 90px;
`

export default Header;