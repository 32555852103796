import { Assessment } from "../../AutomatedAssessment/firestore/Assessment";
import dayjs from "dayjs";

export const activityPercentage = (assessmentList: Assessment[]): number => {
    // Look at the percentage of students active in the last hour
    const activeList = assessmentList.filter(assessment => assessment.isActive)
    let active_count = activeList.length

    let percentage = 0
    if (assessmentList.length > 0) {
        percentage = (active_count * 100) / assessmentList.length
        // To 1 dp
        percentage = Math.round(percentage * 10) / 10
    }
    return percentage
}