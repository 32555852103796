import { collection, addDoc, setDoc } from "@firebase/firestore";
import dayjs from "dayjs";
import { getFirestoreDb, getFirestoreStruct } from "./Firestore";

export const COLLECTION_ENJOYMENT_REQUEST = "EnjoymentRequests";

/**
 * Data structure stored to the firstore database.
 */
export interface EnjoymentRequestInterface {
    courseId: string;
    requestedAt: Date;
}


/**
 * Provides a blank Enjoyment Request interface object
 */
export function createEnjoymentRequestStruct(): EnjoymentRequestInterface {
    return {
        courseId: "",
        requestedAt: dayjs('2000/01/01').toDate()
    }
}

export class EnjoymentRequest implements EnjoymentRequestInterface {
    private _requestedAt: Date;
    private _courseId: string = ""

    constructor(courseId: string) {
        this._courseId = courseId;
        this._requestedAt = new Date()
    }

    get courseId(): string {
        return this._courseId
    }
    get requestedAt(): Date {
        return this._requestedAt
    }
}

export async function saveEnjoymentRequest(enjoymentRequest: EnjoymentRequestInterface): Promise<string> {
    if (enjoymentRequest.courseId == "") {
        throw "Save Enjoyment Request - Course Id has not been set";
    }
    // Create the record for db
    const record = getFirestoreStruct(createEnjoymentRequestStruct(), enjoymentRequest)

    const db = getFirestoreDb();
    const docRef = await addDoc(collection(db, COLLECTION_ENJOYMENT_REQUEST), record);
    return docRef.id
}

