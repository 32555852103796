import styled from 'styled-components';

const Footer = () => {
    return (
        <RoboticalFooter>
            <FooterText>
                &copy; 2022 <a href="https://robotical.io" target="_blank">Robotical</a>. Say hello or ask us a question: <a href="mailto:hello@robotical.io">hello@robotical.io</a>
            </FooterText>
            <FooterText>
                Hairball analysis components Copyright &copy; 2012, Bryce Boe. All rights reserved.
            </FooterText>
        </RoboticalFooter>
    )
}

const RoboticalFooter = styled.footer`
    background-color: #27788c;
    color: var(--colour-GREY_4);
    padding: 15px;
    text-align: center;
    font-size: 0.75em;
`
const FooterText = styled.p`
    padding: 0.25rem;
`

// footer {
//     background-color: #27788c;
//     color: #ececec;
//     padding: 15px;
//     text-align: center;
//     font-size: 0.75em;
// }

// footer a{
//     color: inherit;
// }


export default Footer;