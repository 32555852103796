import { useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js";
import { Line } from "react-chartjs-2";
import { registerables } from "chart.js";
import { AssessmentScoreRangeBucket, fetchAssessmentOverTime } from "../../library/AssessmentAnalysis/AssessmentOverTime";
import { Student } from "../../AutomatedAssessment/firestore/Student";

ChartJS.register(...registerables);

interface GraphCompetenceProps {
    courseId: string;
    student?: Student;
    id?: string;
}
interface DataSet {
    label: string;
    fill?: string;
    backgroundColor: string;
    borderColor: string;

    data: number[];
}
interface GraphData {
    labels: string[];
    datasets: DataSet[];
}

/**
 * Shows Confidence in Competence for a class or a Student.
 * If a Student is passed shows details for that student, otherwise the whole class.
 */
const GraphCompetence = ({ courseId, student, id }: GraphCompetenceProps) => {
    // Graph data stored in state
    const createNewData = (): GraphData => {
        return { labels: [], datasets: [] };
    };
    const [graphData, setgraphData] = useState<GraphData>(createNewData());
    const theStudentId = student ? student.studentId : ""

    useEffect(() => {
        if (courseId === "") {
            setgraphData(createNewData());
        } else {
            fetchAssessmentOverTime(courseId, theStudentId).then((competence) => {

                const results = competence.assessmentCompentencyBucketList();
                const newData = createNewData();

                newData.labels = competence.labelTimeLine();

                if (student) {
                    newData.datasets.push({
                        label: student.name,
                        backgroundColor: "rgba(54, 162, 235, 1)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        data: results.map((dataPoint: AssessmentScoreRangeBucket) => dataPoint.scoreRange.average),
                    });
                } else {
                    newData.datasets.push({
                        label: "Average",
                        backgroundColor: "rgba(54, 162, 235, 1)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        data: results.map((dataPoint: AssessmentScoreRangeBucket) => dataPoint.scoreRange.average),
                    });
                    newData.datasets.push({
                        label: "Highest",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        data: results.map((dataPoint: AssessmentScoreRangeBucket) => dataPoint.scoreRange.highest),
                        fill: "+1",
                    });
                    newData.datasets.push({
                        label: "Lowest",
                        backgroundColor: "rgba(255, 99, 132, 1)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        data: results.map((dataPoint: AssessmentScoreRangeBucket) => dataPoint.scoreRange.lowest),
                    });
                }

                setgraphData(newData);
            });
        }
    }, [courseId, theStudentId]);

    let title = "Confidence in Competence"

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: title,
            },
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: "Month",
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: "%",
                },
            },
        },
    };


    return (
        <div>
            <div className="code-assess-graph-title" id={id}>
                Confidence in Competence
            </div>
            <Line options={options} data={graphData} />
        </div>
    );
};

export default GraphCompetence;
