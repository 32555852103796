import { useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js";
import { Bar } from "react-chartjs-2";
import { registerables } from "chart.js";
import { Student } from "../../AutomatedAssessment/firestore/Student";
import { CourseEnjoymentRatingBucket, fetchEnjoymentOverTime } from "../../library/AssessmentAnalysis/EnjoymentOverTime";

ChartJS.register(...registerables);

interface GraphEnjoymentProps {
    courseId: string;
    id?: string;
    student?: Student;
}
interface DataSet {
    label: string;
    fill?: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    barPercentage: number;

    data: number[][];
}
interface GraphData {
    labels: string[];
    datasets: DataSet[];
}

const GraphEnjoyment = ({ id, student, courseId }: GraphEnjoymentProps) => {
    // Graph data stored in state
    const createNewData = (): GraphData => {
        return { labels: [], datasets: [] };
    };
    const [graphData, setgraphData] = useState<GraphData>(createNewData());
    const theStudentId = (student ? student.studentId : "")

    useEffect(() => {
        if (courseId === "") {
            // Clear graph if no course selected.
            setgraphData(createNewData());
        } else {
            fetchEnjoymentOverTime(courseId, theStudentId).then((enjoymentOverTime) => {
                const results = enjoymentOverTime.enjoymentRangeBucketList();

                const newData = createNewData();
                newData.labels = enjoymentOverTime.labelTimeLine();

                const highest = results.map((dataPoint: CourseEnjoymentRatingBucket) => [dataPoint.average, dataPoint.highest]);
                const lowest = results.map((dataPoint: CourseEnjoymentRatingBucket) => [dataPoint.lowest, dataPoint.average]);
                const average = results.map((dataPoint: CourseEnjoymentRatingBucket) => [0, dataPoint.average]);

                if (student) {
                    newData.datasets.push({
                        label: student.name,
                        data: average,
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        borderWidth: 2,
                        barPercentage: 0.6,
                    });
                } else {
                    newData.datasets.push({
                        label: "Highest",
                        data: highest,
                        backgroundColor: "rgba(75, 192, 192, 0.5)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        borderWidth: 0,
                        barPercentage: 0.4,
                    });
                    newData.datasets.push({
                        label: "Lowest",
                        data: lowest,
                        backgroundColor: "rgba(255, 99, 132, 0.25)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        borderWidth: 0,
                        barPercentage: 0.4,
                    });
                    newData.datasets.push({
                        label: "Average",
                        data: average,
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        borderWidth: 2,
                        barPercentage: 0.6,
                    });
                }

                setgraphData(newData);
            });
        }
    }, [courseId, theStudentId]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Enjoyment",
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: false,
            },
        },
    };

    return (
        <div>
            <div className="code-assess-graph-title" id={id}>
                Enjoyment
            </div>
            <Bar options={options} data={graphData} />
        </div>
    );
};

export default GraphEnjoyment;
