import { useEffect } from "react"
import styled from "styled-components"
import { AssessmentUserInterface, createAssessmentLogin } from "../../AutomatedAssessment/user/AssessmentUser"
import { Button, Image } from "react-bulma-components"
import { useUser, LOGIN, LOGOUT } from "./../../contexts/UserContext"

const HeaderLogin = () => {
    const userContext = useUser()

    useEffect(() => {
        const loginHandler = createAssessmentLogin();

        const loginStatusUpdate = (user: AssessmentUserInterface) => {
            const action = user.isLoggedIn ? LOGIN : LOGOUT;
            userContext.dispatch({ type: action, payload:user })
        }

        loginHandler.init(loginStatusUpdate)

    }, []);

    const handleLogin = () => {
        const loginHandler = createAssessmentLogin();
        loginHandler.signIn()
    }
    
    return (
        <LoginBox>
            <LoginButton src="/images/google/btn_google_signin_dark_normal_web.png" onClick={handleLogin} />
        </LoginBox>
    )
}

const LoginButton = styled.img`
    width: 191px;
    height: 46px;
    cursor: pointer;
`

const LoginBox = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
`

export default HeaderLogin;