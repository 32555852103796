import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import LiveView from './screens/LiveView';
import { UserProvider } from './contexts/UserContext'
import { pageWrapper } from './components/Layout/PageWrapper';
import StudentsView from './screens/StudentsView';



function App() {
    const HomeWrapped = pageWrapper(HomeScreen)
    const LiveViewWrapped = pageWrapper(LiveView)
    const StudentsViewWrapped = pageWrapper(StudentsView)


    return (
        <UserProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomeWrapped />} />
                    <Route path="/live-view" element={<LiveViewWrapped />} />
                    <Route path="/students" element={<StudentsViewWrapped />} />
                </Routes>
            </BrowserRouter>
        </UserProvider>
    );
}

export default App;
