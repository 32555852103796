import { ControlBoxLink, ControlBoxTitle, ControlBoxSubtitle } from "./styles"

interface PauseButtonProps {
    onPlayClick(current: boolean): void;
    running: boolean
}

const PauseButton = ({ onPlayClick, running }: PauseButtonProps) => {
    return (
        <ControlBoxLink onClick={() => onPlayClick(running)}>
            {running && (
                <div>
                    <ControlBoxTitle>⏸️</ControlBoxTitle>
                    <ControlBoxSubtitle>Started</ControlBoxSubtitle>
                </div>
            )}
            {!running && (
                <div>
                    <ControlBoxTitle>▶️</ControlBoxTitle>
                    <ControlBoxSubtitle>Paused</ControlBoxSubtitle>
                </div>
            )}
        </ControlBoxLink>
    )
}

export default PauseButton