import { Chart as ChartJS } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from "styled-components"
import { AssessmentLabels } from '../../library/AssessmentGraph';
import { registerables } from 'chart.js';
import { Assessment } from "../../AutomatedAssessment/firestore/Assessment";
import { Student } from '../../AutomatedAssessment/firestore/Student';


ChartJS.register(...registerables);

interface GraphAssessmentProps {
    assessmentScores: Assessment;
    student?: Student;
}

/**
 * Shows current assessment score.
 * Either average of scores passed or current score for a given student.
 */
const GraphAssessment = ({ assessmentScores, student }: GraphAssessmentProps) => {
    const labels = AssessmentLabels

    const dataValues = [
        assessmentScores.flowControl, assessmentScores.dataRepresentation, assessmentScores.abstraction,
        assessmentScores.interactivity, assessmentScores.synchronisation, assessmentScores.parallelism, assessmentScores.logic
    ]

    const title = (student) ? student.name + ' Scores' : 'Average Scores'

    const chartScores = {
        labels: labels,
        datasets: [
            {
                label: 'Progress',
                data: dataValues,
                borderColor: 'rgba(255, 255, 255, 1.0)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                barPercentage: 0.5
            },
        ]
    };

    const chartOptions = {
        indexAxis: 'y' as const,
        responsive: true,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title,
                color: 'white'
            },
        },
        scales: {
            x: {
                display: true,
                max: 3,
                title: {
                    display: true,
                    text: 'Score',
                    color: 'rgba(255, 255, 255, 1.0)'
                },
                ticks: {
                    color: 'rgba(255, 255, 255, 1.0)'
                },
                grid: {
                    color: 'rgba(255, 255, 255, 1.0)',
                }
            },
            y: {
                ticks: {
                    color: 'rgba(255, 255, 255, 1.0)'
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.25)',
                }
            }
        }
    }

    return (
        <LiveViewChart>
            <Bar options={chartOptions} data={chartScores} />
        </LiveViewChart>
    )
}

const LiveViewChart = styled.div`
    diplay:flex;
    background: var(--colour-MAIN_BLUE);
    border-radius: 5px;
    max-height:100%;
`
export default GraphAssessment