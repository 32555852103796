import { usingFirebase } from "../../AutomatedAssessment/firestore/Firestore";
import { getAssessmentHistory, getStudentAssessmentHistory } from "../../AutomatedAssessment/firestore/FirestoreQuery";
import dayjs from "dayjs";
import { Assessment, AssessmentInterface } from "../../AutomatedAssessment/firestore/Assessment";

/**
 * Provides an assessment over time object (via promise) for the course passed.
 */
export function fetchBadHabitsOverTime(courseId: string, studentId: string): Promise<BadHabitsOverTime> {
    return new Promise<BadHabitsOverTime>((resolve, reject) => {
        if (courseId === "") {
            resolve(new BadHabitsOverTime([]));
        }
        const assessmentFetch = (courseId: string, studentId: string, since: Date): Promise<Assessment[]> => {
            if (studentId === "") {
                return getAssessmentHistory(courseId, since)
            }
            return getStudentAssessmentHistory(courseId, studentId, since)
        }

        usingFirebase()
            .then(() => {
                let since = dayjs().startOf("month").subtract(6, "month");

                assessmentFetch(courseId, studentId, since.toDate())
                    .then((assessmentList: Assessment[]) => {
                        const badHabitsOverTime = new BadHabitsOverTime(assessmentList);

                        resolve(badHabitsOverTime);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            })
            .catch(() => {
                reject("Unable to connect to database");
            });
    });
}

export interface BadHabitScoreInterface {
    duplication: number,
    deadCode: number,
    badNaming: number,
}

class BadHabitsOverTime {
    private assessmentList: AssessmentInterface[];

    constructor(assessmentList: AssessmentInterface[]) {
        this.assessmentList = assessmentList;
    }

    /**
     *
     * Get the average bad habit scores for the assessment list supplied.
     */
    getResults(): BadHabitScoreInterface {
        const initial: BadHabitScoreInterface = {
            duplication: 0,
            deadCode: 0,
            badNaming: 0
        }
        const len = this.assessmentList.length
        if (len == 0) {
            return initial
        }

        let result = this.assessmentList
            .map((assessment: AssessmentInterface) => {
                return {
                    duplication: assessment.duplication, deadCode: assessment.deadCode, badNaming: assessment.badNaming
                }
            })
            .reduce((prev: BadHabitScoreInterface, current: BadHabitScoreInterface) => {
                return {
                    duplication: prev.duplication + current.duplication,
                    deadCode: prev.deadCode + current.deadCode,
                    badNaming: prev.badNaming + current.badNaming,
                }
            }, initial)

        result = {
            duplication: Math.round(10 * result.duplication / len) / 10,
            deadCode: Math.round(10 * result.deadCode / len) / 10,
            badNaming: Math.round(10 * result.badNaming / len) / 10,
        }
        return result
    }
}