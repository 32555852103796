import { totalmem } from "os";
import { AssessmentScoreInterface } from "../..";

export function createAssessmentScoreInterface() {
    return {
        abstraction: 0,
        dataRepresentation: 0,
        flowControl: 0,
        interactivity: 0,
        logic: 0,
        parallelism: 0,
        synchronisation: 0,
    };
}

export function copyAssessmentScore(fromScore: AssessmentScoreInterface, toScore: any) {
    toScore.abstraction = fromScore.abstraction;
    toScore.dataRepresentation = fromScore.dataRepresentation;
    toScore.flowControl = fromScore.flowControl;
    toScore.interactivity = fromScore.interactivity;
    toScore.logic = fromScore.logic;
    toScore.parallelism = fromScore.parallelism;
    toScore.synchronisation = fromScore.synchronisation;
}

export function addAssessmentScores(first: AssessmentScoreInterface, second: AssessmentScoreInterface): AssessmentScoreInterface {
    const assessment = { ...first };
    assessment.abstraction += second.abstraction;
    assessment.dataRepresentation += second.dataRepresentation;
    assessment.flowControl += second.flowControl;
    assessment.interactivity += second.interactivity;
    assessment.logic += second.logic;
    assessment.parallelism += second.parallelism;
    assessment.synchronisation += second.synchronisation;
    return assessment;
}

export function totalScore(assessmentScore: AssessmentScoreInterface) {
    let total: number = 0
    total += assessmentScore.abstraction;
    total += assessmentScore.dataRepresentation;
    total += assessmentScore.flowControl;
    total += assessmentScore.interactivity;
    total += assessmentScore.logic;
    total += assessmentScore.parallelism;
    total += assessmentScore.synchronisation;
    return total
}

export function divideAssessmentScore(numerator: AssessmentScoreInterface, denominator: number): AssessmentScoreInterface {
    if (denominator <= 1) {
        return numerator;
    }
    const assessment = { ...numerator };
    assessment.abstraction /= denominator;
    assessment.dataRepresentation /= denominator;
    assessment.flowControl /= denominator;
    assessment.interactivity /= denominator;
    assessment.logic /= denominator;
    assessment.parallelism /= denominator;
    assessment.synchronisation /= denominator;
    return assessment;
}

export function averageAssessmentScoreAverage(assessmentScores: AssessmentScoreInterface[]): AssessmentScoreInterface {
    let count = 0;
    const total: AssessmentScoreInterface = assessmentScores.reduce((previous: AssessmentScoreInterface, current: AssessmentScoreInterface) => {
        ++count;
        return addAssessmentScores(previous, current);
    }, createAssessmentScoreInterface());

    return divideAssessmentScore(total, count);
}
